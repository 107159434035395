import { SelectItem } from '../../fs-controls-shared.model';

export class FSButtonsValue {
  renderTemplateBeforeChangeValue: boolean;

  constructor(model: FSButtonsValue) {
    this.renderTemplateBeforeChangeValue =
      model.renderTemplateBeforeChangeValue || false;
  }
}

export class FSButtonsGroup {
  id?: number;
  buttonsPerView?: number;
  //currentValue?: SelectItem;
  value?: string;
  buttonsSize?: number;
  buttonsHeight?: number;
  hideNav?: boolean;
  classes?: string;
  direction?: 'column' | 'row';
  values?: SelectItem<FSButtonsValue>[];

  constructor(model: FSButtonsGroup) {
    this.id = model.id || 0;
    this.buttonsPerView = model.buttonsPerView || 3;
    //this.currentValue = model.currentValue || new SelectItem({});
    this.value = model.value || '';
    this.buttonsSize = model.buttonsSize || 100;
    this.buttonsHeight = model.buttonsHeight || 53;
    this.hideNav = model.hideNav || false;
    this.classes = model.classes || '';
    this.direction = model.direction || 'row';
    this.values = model.values || new Array<SelectItem<FSButtonsValue>>();
  }
}
