import { fullCheckNumber } from '../helper-functions';
import { ConditionsDefaults, PreferencesDefaults } from '../static-objects';
import { BaseDTO } from './base-dto.model';

export class CreateSimulatorPreferenceRequest {
  soundFx?: boolean;
  fullShotUnitId?: number;
  puttingUnitId?: number;
  teePositionId?: number;
  tracerViewId?: number;
  playerViewId?: number;
  puttingGridId?: number;
  puttingArrowId?: number;

  constructor(model: CreateSimulatorPreferenceRequest) {
    this.soundFx = model.soundFx || false;
    this.fullShotUnitId = model.fullShotUnitId || 0;
    this.puttingUnitId = model.puttingUnitId || 0;
    this.teePositionId = model.teePositionId || 0;
    this.tracerViewId = model.tracerViewId || 0;
    this.playerViewId = model.playerViewId || 0;
    this.puttingGridId = model.puttingGridId || 0;
    this.puttingArrowId = model.puttingArrowId || 0;
  }
}

export class UpdateSimulatorPreferenceRequest {
  teePositionId?: number;
  mulligans?: number;
  gimmieDistanceId?: number;
  tracerViewId?: number;
  playerViewId?: number;
  fullShotUnitId?: number;
  puttingUnitId?: number;
  soundFx?: boolean;
  pinPositionId?: number;
  greenSpeedId?: number;
  greenFirmnessId?: number;
  puttingGridId?: number;
  puttingArrowId?: number;
  fairwayFirmnessId?: number;
  windSettingId?: number;
  courseElevation?: number;

  constructor(model: UpdateSimulatorPreferenceRequest) {
    this.teePositionId = model.teePositionId || 1;
    this.mulligans = model.mulligans === null ? 1000 : model.mulligans;
    this.gimmieDistanceId =
      model.gimmieDistanceId || PreferencesDefaults.Gimmie;
    this.tracerViewId = model.tracerViewId || PreferencesDefaults.TracerView;
    this.playerViewId = model.playerViewId || PreferencesDefaults.PlayerView;
    this.fullShotUnitId = model.fullShotUnitId || 1;
    this.puttingUnitId = model.puttingUnitId || 1;
    this.soundFx = model.soundFx || false;
    this.pinPositionId = model.pinPositionId || 1;
    this.greenSpeedId = model.greenSpeedId || ConditionsDefaults.GreenSpeed;
    this.greenFirmnessId =
      model.greenFirmnessId || ConditionsDefaults.GreenFirmness;
    this.puttingGridId = model.puttingGridId || ConditionsDefaults.PuttingGrid;
    this.puttingArrowId =
      model.puttingArrowId || ConditionsDefaults.PuttingArrow;
    this.fairwayFirmnessId =
      model.fairwayFirmnessId || ConditionsDefaults.FairwayFirmness;
    this.windSettingId = model.windSettingId || ConditionsDefaults.WindSetting;

    /*Course elevation values
     * view 1 => value: -1
     * view 0 => value: 0
     * view -1 => value: over than 0
     */
    if (model.courseElevation !== -1) {
      if (fullCheckNumber(model.courseElevation))
        this.courseElevation = model.courseElevation;
      else {
        this.courseElevation = 0; //5279;
      }
    } else {
      this.courseElevation = -1;
    }
  }
}

export class SimulatorPreferenceResult extends BaseDTO {
  playerId?: number;
  teePositionId?: number;
  mulligans?: number;
  gimmieDistanceId?: number;
  tracerViewId?: number;
  playerViewId?: number;
  fullShotUnitId?: number;
  puttingUnitId?: number;
  soundFx?: boolean;
  pinPositionId?: number;
  greenSpeedId?: number;
  greenFirmnessId?: number;
  puttingGridId?: number;
  puttingArrowId?: number;
  fairwayFirmnessId?: number;
  windSettingId?: number;
  courseElevation?: number;

  //Custom fields
  courseElevationCustom?: number;

  constructor(model: SimulatorPreferenceResult) {
    super(model);

    this.playerId = model.playerId || 1;
    this.teePositionId = model.teePositionId || 1;
    this.mulligans = model.mulligans === null ? 1000 : model.mulligans;
    this.gimmieDistanceId =
      model.gimmieDistanceId || PreferencesDefaults.Gimmie;
    this.tracerViewId = model.tracerViewId || PreferencesDefaults.TracerView;
    this.playerViewId = model.playerViewId || PreferencesDefaults.PlayerView;
    this.fullShotUnitId = model.fullShotUnitId || 1;
    this.puttingUnitId = model.puttingUnitId || 1;
    this.soundFx = model.soundFx ?? true;
    this.pinPositionId = model.pinPositionId || 1;
    this.greenSpeedId = model.greenSpeedId || ConditionsDefaults.GreenSpeed;
    this.greenFirmnessId =
      model.greenFirmnessId || ConditionsDefaults.GreenFirmness;
    this.puttingGridId = model.puttingGridId || ConditionsDefaults.PuttingGrid;
    this.puttingArrowId =
      model.puttingArrowId || ConditionsDefaults.PuttingArrow;
    this.fairwayFirmnessId =
      model.fairwayFirmnessId || ConditionsDefaults.FairwayFirmness;
    this.windSettingId = model.windSettingId || ConditionsDefaults.WindSetting;

    if (fullCheckNumber(model.courseElevation))
      this.courseElevation = model.courseElevation;
    else {
      this.courseElevation = -1; //5279;
    }

    //Custom fields
    this.courseElevationCustom = model.courseElevationCustom || 0;
  }
}
