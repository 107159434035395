import { Injectable } from '@angular/core';
import { PlayerResult } from '../models/player.model';
import { VsResult } from '../enums/vs-result.enum';
import { Subject } from 'rxjs';
import { getUpperFirstChars } from '../helper-functions';
import { EventPlayerForEventsResult } from '../models/event-player.model';
import { FreeAgentResult } from '../models/free-agent.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private playerSub = new Subject<PlayerResult>();
  public playerSub$ = this.playerSub.asObservable();

  private _player: PlayerResult;
  public get player(): PlayerResult {
    return this._player;
  }
  public set player(v: PlayerResult) {
    this._player = new PlayerResult({ ...v });
    this.playerSub.next(this._player);
    this.setImageName();
  }

  private _imageProfileName: string;
  public get imageProfileName(): string {
    return this._imageProfileName;
  }
  public set imageProfileName(v: string) {
    this._imageProfileName = v;
  }

  constructor() {
    this._player = new PlayerResult({});
    this._imageProfileName = '';
  }

  public getVsResult(value: VsResult): string {
    switch (value) {
      case VsResult.Loss:
        return 'Loss';
      case VsResult.Win:
        return 'Win';
      case VsResult.Tie:
        return 'Tie';
      default:
        return '';
    }
  }

  public setImageName(): void {
    this._imageProfileName = getUpperFirstChars(
      `${this.player.firstName?.replaceAll(
        ' ',
        ''
      )} ${this.player.lastName?.replaceAll(' ', '')}`
    );
  }

  public eventIsRegistered(
    players: EventPlayerForEventsResult[],
    agents: FreeAgentResult[]
  ): boolean {
    if (
      players?.find((f) => f.player.id === this._player.id) ||
      agents?.find((f) => f.player.id === this._player.id)
    ) {
      return true;
    }

    return false;
  }
}
