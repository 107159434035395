import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { NavigationService } from './navigation.service';
import { FSMiniLoaderService } from 'src/app/shared/modules/fs-mini-loader/fs-mini-loader.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  NavbarItemsData,
  SettingsPageTabData,
} from 'src/app/shared/static-objects';
import { HelperService } from 'src/app/shared/services/helper.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { FSTabBar } from 'src/app/shared/models/application.model';
import {
  FSPageHistoryOption,
  PageHistoryType,
} from 'src/app/shared/modules/fs-page-frame/fs-page-frame.model';
import { PageFrameService } from 'src/app/shared/modules/fs-page-frame/fs-page-frame.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @ViewChild('navBGContent') navBGContent: ElementRef;

  isActiveProfileBody: boolean;

  constructor(
    public globalService: GlobalService,
    public authService: AuthService,
    public navService: NavigationService,
    public helperService: HelperService,
    private loaderService: FSMiniLoaderService,
    private router: Router,
    private pageFrameService: PageFrameService
  ) {
    this.navService.navRouteToggle = false;
    this.isActiveProfileBody = false;
    this.navService.navItems = NavbarItemsData;
  }

  ngAfterViewInit(): void {
    let navBGEl = this.navBGContent.nativeElement as HTMLElement;

    if (navBGEl) {
      let screenWidth = window.innerWidth;

      if (screenWidth <= 1360) {
        navBGEl.classList.add('navbar-bg-float');
        navBGEl.style.left = `${((1922 - screenWidth) / 2) * -1}px`;

        if (screenWidth >= 760) {
          navBGEl.style.height = `${
            80 - Math.floor((1360 - screenWidth) / 17)
          }px`;
        } else if (screenWidth < 760) {
          navBGEl.style.height = `45px`;
        }
      }
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects?.length) {
          let urlSplited = val.urlAfterRedirects.split('/');

          if (urlSplited?.length && urlSplited[1]) {
            this.navService.setNavItemActivate(urlSplited[1]);
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.navService.setNavItemActivate(this.navService.currentRoute);
    this.navService.initialNavItemActive();
  }

  onExpandProfile(): void {
    this.isActiveProfileBody = !this.isActiveProfileBody;
  }

  onSettings(): void {
    this.router.navigate(['settings']).then((_) => {
      let tab: FSTabBar = SettingsPageTabData.find((f) => f.id === 1);

      this.pageFrameService.saveHistory(
        new FSPageHistoryOption({
          route: 'settings',
          id: tab?.id,
          title: tab.name,
          type: PageHistoryType.ChangeTab,
        })
      );

      //location.reload();
    });
    this.onExpandProfile();
  }

  onExit(): void {
    this.loaderService.loader = true;
    this.onExpandProfile();
    this.authService.unautorized().then((_) => {
      window.location.reload();
    });
  }
}
