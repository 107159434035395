<div class="profile-image" *ngIf="globalService.player.profileImageUrl?.length && !hasError; else profileImageChar">
    <fs-image [src]="globalService.player.profileImageUrl" [width]="width" [height]="height"
        (hasError)="hasError = $event"></fs-image>
</div>
<ng-template #profileImageChar>
    <div class="profile-placeholder" [ngStyle]="{ width: width + 'px', height: height + 'px' }">
        <span [ngStyle]="{'font-size': 'calc(' + width + 'px * (56 / 100))'}">
            {{ globalService.imageProfileName }}
        </span>
    </div>
</ng-template>