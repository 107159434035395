export class FSChips {
  id?: number;
  value?: string;
  title?: string;
  icon?: string;
  isDisabled?: boolean;

  constructor(model: FSChips) {
    this.id = model.id || 0;
    this.value = model.value || '';
    this.title = model.title || '';
    this.icon = model.icon || '';
    this.isDisabled = model.isDisabled || false;
  }
}
