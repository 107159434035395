export class AWSResponse<T = null> {
  type?: AWSResponseType;
  message?: string;
  error?: any;
  data?: T;

  constructor(model: AWSResponse<T>) {
    this.type = model.type || AWSResponseType.Unknown;
    this.message = model.message || '';
    this.error = model.error || (null as any);
    this.data = model.data || (null as any);
  }
}

export enum AWSResponseName {
  UserNotConfirmed = 'UserNotConfirmedException',
  UserIsExists = 'UsernameExistsException',
}

export enum AWSResponseType {
  Unknown = 0,
  Success = 1,
  NewPasswordRequired = 2,
  UserIsExists = 3,
  UserNotExist = 4,
  UserNotConfirmed = 5,
}

export class AWSErrorHandler {
  public static exception(error: any): AWSResponseType {
    if (error?.message) {
      const message = (error.message as string).toLowerCase();

      if (
        error?.name === AWSResponseName.UserNotConfirmed ||
        message.search('user is not confirmed') > -1
      ) {
        return AWSResponseType.UserNotConfirmed;
      }

      if (
        error?.name === AWSResponseName.UserIsExists ||
        message.search('account with the given email already exists') > -1
      ) {
        return AWSResponseType.UserIsExists;
      }
    }

    return AWSResponseType.Unknown;
  }

  public static message(error: any): string {
    if (error?.message) return error.message;
    return '';
  }

  public static result<T = any>(error: any, data?: T): AWSResponse<T> {
    return new AWSResponse<T>({
      data,
      error,
      message: this.message(error),
      type: this.exception(error),
    });
  }
}
