import { SelectItem } from "../../fs-controls-shared.model";

export class FSSimpleSelector {
  id?: number;
  label?: string;
  name?: string;
  values?: SelectItem[];

  constructor(model: FSSimpleSelector) {
    this.id = model.id || 0;
    this.label = model.label || '';
    this.name = model.name || '';
    this.values = model.values || new Array<SelectItem>();
  }
}
