import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'fs-image',
  templateUrl: './fs-image.component.html',
  styleUrls: ['./fs-image.component.scss'],
})
export class FsImageComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('src') src: string;
  @Input('alt') alt: string;

  @Input('width') width: number;
  @Input('height') height: number;

  @Input('defaultImageSrc') defaultImageSrc: string;
  @Input('hasDefaultImage') hasDefaultImage: boolean;
  @Input('placeholder') placeholder: string;
  @Input('placeholderRatio') placeholderRatio: number;

  @Input('classes') classes: string;

  @Output('hasError') hasError = new EventEmitter<boolean>(false);

  constructor() {
    this.src = this.alt = '';
    this.width = this.height = 0;
    this.hasDefaultImage = false;
    this.placeholder = null;
    this.placeholderRatio = 20;
    this.classes = '';
    this.defaultImageSrc = './assets/svgs/Full-Swing.svg';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']) {
      this.hasError.emit(false);
      this.setDefaultImage();
    }
  }

  ngAfterViewInit(): void {
    this.setDefaultImage();
  }

  ngOnInit(): void {
    this.setDefaultImage();
  }

  onError(): void {
    if (this.hasDefaultImage) {
      this.src = this.defaultImageSrc;
    }

    this.hasError.emit(true);
  }

  setDefaultImage(): void {
    if (this.hasDefaultImage) {
      if (!this.src || !this.src.length) {
        this.src = this.defaultImageSrc;
      }
    }
  }
}
