export enum UrlParameterFor {
  None = 0,
  ConfirmAccount = 1,
  ResetPassword = 2,
  RequestToJoinTeam = 3,
}

export class ConfirmUserModel {
  username?: string;
  code?: string;
  type?: number;

  constructor(model: ConfirmUserModel) {
    this.username = model.username || '';
    this.code = model.code || '';
    this.type = model.type || 0;
  }
}

export class RequestToJoinTeamParamModel {
  playerId?: number;
  teamId?: number;
  notificationId?: number;

  constructor(model: RequestToJoinTeamParamModel) {
    this.playerId = model.playerId || 0;
    this.teamId = model.teamId || 0;
    this.notificationId = model.notificationId || 0;
  }
}
