const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_B0NCikVLQ",
  aws_user_pools_web_client_id: "1nrgib91ejq8vg19i0kt1d8f8b",
  oauth: {
    domain: "bay-manager.auth.us-west-2.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://devcompete.fullswingapps.com/login/",
    redirectSignOut: "https://devcompete.fullswingapps.com/login",
    responseType: "code",
  },
};

export default awsmobile;
