export class FSStackCell {
  value?: any;
  classes?: string;

  constructor(model: FSStackCell) {
    this.value = model.value || (null as any);
    this.classes = model.classes || '';
  }
}

export class FSStackListItem {
  label?: string;
  name?: string;
  classes?: string;
  cells?: FSStackCell[];

  constructor(model: FSStackListItem) {
    this.label = model.label || '';
    this.name = model.name || '';
    this.classes = model.classes || '';
    this.cells = model.cells || new Array<FSStackCell>();
  }
}

export class FSStackList {
  id?: number;
  classes?: string;
  items?: FSStackListItem[];

  constructor(model: FSStackList) {
    this.id = model.id || 0;
    this.classes = model.classes || '';
    this.items = model.items || new Array<FSStackListItem>();
  }
}
