import { Component, Input } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'fs-profile-image',
  templateUrl: './fs-profile-image.component.html',
  styleUrls: ['./fs-profile-image.component.scss'],
})
export class FsProfileImageComponent {
  @Input('width') width: number;
  @Input('height') height: number;

  readonly defaultImageProfile: string;

  private _hasError: boolean;
  public get hasError(): boolean {
    return this._hasError;
  }
  public set hasError(v: boolean) {
    this._hasError = v;
  }

  constructor(public globalService: GlobalService) {
    this._hasError = false;
    this.defaultImageProfile = './assets/icons/User-42.svg';
    this.width = 100;
    this.height = 100;
  }
}
