import { FSActionElement } from '../../fs-controls-shared.model';
import { FSTableRecord } from '../../fs-controls/fs-table/fs-table.model';

export class FSLeagueTableHeader {
  players?: number;
  name?: string;
  isHide?: boolean;
  actions?: FSActionElement<any>[];

  constructor(model: FSLeagueTableHeader) {
    this.players = model.players || 0;
    this.name = model.name || '';
    this.isHide = model.isHide || false;
    this.actions = model.actions || new Array<FSActionElement<any>>();
  }
}

export class FSLeagueTable {
  id?: number;
  header?: FSLeagueTableHeader;
  records?: FSTableRecord[];
  classes?: string;

  constructor(model: FSLeagueTable) {
    this.id = model.id || 0;
    this.header = model.header || new FSLeagueTableHeader({});
    this.records = model.records || new Array<FSTableRecord>();
    this.classes = model.classes || '';
  }
}

export class FSLeagueOption {
  title?: string;
  sizeOfColumns?: string[];
  actions?: FSActionElement<any>[];
  groups?: FSLeagueTable[];
  width?: string;
  hidePlayersQuantity?: boolean;

  constructor(model: FSLeagueOption) {
    this.title = model.title || '';
    this.sizeOfColumns = model.sizeOfColumns || new Array<string>();
    this.actions = model.actions || new Array<FSActionElement<any>>();
    this.groups = model.groups || new Array<FSLeagueTable>();
    this.width = model.width || '100%';
    this.hidePlayersQuantity = model.hidePlayersQuantity || false;
  }
}
