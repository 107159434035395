import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsProfileImageComponent } from './fs-profile-image.component';
import { FsImageModule } from '../../fs-controls/fs-image/fs-image.module';

@NgModule({
  declarations: [FsProfileImageComponent],
  imports: [CommonModule, FsImageModule],
  exports: [FsProfileImageComponent],
})
export class FsProfileImageModule {}
