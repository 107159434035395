import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable, inject } from '@angular/core';
import { AuthState } from 'src/app/shared/enums/auth-state.enum';

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isTokenValid().then((validation) => {
      if (validation) {
        //this.authService.isAuthenticate = true;
        return true;
      } else {
        this._router.navigate(['login']).then(() => {
          this.authService.isAuthenticate = AuthState.UseAuth;
        });
        return false;
      }
    });
  }
}


// export const canActivate: CanActivateFn = (
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot
// ): Observable<boolean> | Promise<boolean> | boolean => {
//   const authService = inject(AuthService);
//   const router = inject(Router);

//   return authService.isTokenValid().then((validation) => {
//     if (validation) {
//       //this.authService.isAuthenticate = true;
//       return true;
//     } else {
//       router.navigate(['login']).then(() => {
//         authService.isAuthenticate = AuthState.False;
//       });
//       return false;
//     }
//   }); 
// }